.calculate {
  padding-top: 12rem;
  background-color: #f9f7f7;
  @include respond(phone) {
    padding-top: 8rem;
  }

  &__box {
    margin-left: calc(25% + 1rem);
    width: calc(50% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    text-align: center;

    @include respond(tab-port) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    &__title {
      margin-bottom: 4.5rem;
      & h3 {
        color: #4a4a49;
        font-family: 'Krylon', serif;
        font-size: 5.2rem;
          @include respond(tab-port) {
              font-size: 4rem;
          }
        line-height: 1em;
        font-weight: 100;
      }
    }

    &__text {
      margin-bottom: 4.5rem;
      & p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5em;
        color: #4a4a49;
      }
    }

    &__bottom {
      margin-bottom: 12rem;
      display: flex;
      justify-content: center;

      @include respond(phone) {
        margin-bottom: 8rem;
      }

      &__container {
        width: 30rem;
        height: 7.5rem;

        &__btn {
          border: 1px solid #4a4a49;
          height: 100%;
          width: 100%;
          transition: border-color .4s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: currentColor;
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          background: transparent;
          box-shadow: none;
            text-decoration: none;

          &:hover {
            border-color: #b89535;
            & .calculate__box__bottom__container__btn__icon {
              transform: translateX(.7rem);
              color: #b89535;

              & svg path {
                stroke: #b89535;
              }
            }

            & .calculate__box__bottom__container__btn__text {
              transform: translateX(.8rem);
              transition-delay: .1s;
              color: #b89535;
            }
          }

          &__icon {
            width: 2rem;
            height: 2rem;
            transition: transform .4s ease;
            margin-right: 2.7rem;
            display: flex;
            align-items: center;
            justify-content: center;

            & svg {
              stroke: currentColor;
              transition: stroke .4s ease;
            }
          }

          &__text {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.5em;
            color: #4a4a49;
            transition: transform .4s ease,color .4s ease;
          }
        }
      }
    }
  }
}
