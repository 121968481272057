.gallery {
  width: 100%;
  background: white;
  padding-top: 12rem;
  @include respond(phone) {
    padding-top: 8rem;
  }

  &__box {
    width: calc(83.3333% - 2rem);
    margin-left: calc(8.33333% + 1rem);
    padding-bottom: 12rem;
      @include respond(phone) {
          padding-bottom: 8rem;
      }

    &__content {
      display: flex;
      padding: 2px;
      transition: .3s;
      flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;

      &:hover &__image {
        //filter: grayscale(.4);
      }

      &__column {
        display: flex;
        flex-direction: column;
        width: 25%;
      }

      &__link {
        margin: 8px;
        overflow: hidden;
        cursor: pointer;
          width: calc(25% - 16px);
          @include respond(tab-land) {
              width: calc(33.33333% - 16px);
          }
          @include respond(tab-port) {
              width: calc(50% - 16px);
          }
          @include respond(phone) {
              width: calc(100% - 16px);
          }

        &:hover {
          .gallery__box__content__image {
            //filter: grayscale(0);
          }

          .gallery__box__content__caption {
            opacity: 1;
          }
        }
      }

      &__thumb {
        position: relative;
      }

      &__image {
        display: block;
        width: 100%;
        transition: .3s;

        &:hover {
          transform: scale(1.1);
        }
      }

      &__caption {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 25px 15px 15px;
        width: 100%;
        font-size: 16px;
        color: white;
        opacity: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(255, 255 ,255 , 0) 100%);
        transition: .3s;
      }
    }

  }
}
