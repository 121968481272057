@font-face {
  font-family: 'Avenir';
  src: url(/public/files/fonts/Avenir.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Krylon';
  src: url(/public/files/fonts/Krylon-Regular.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

$color-primary: #000000;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary: #ffffff;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eeeeee;

$color-orange: #b89535;

$color-gray-dark: #777;
$color-gray-dark-2: #333;

$color-white: #fff;
$color-black: #000;

$color-main: #ffffff;
$color-silver: #C0C0C0;
$color-font-white: #ebebeb;

$default-font-size: 2rem;
$font-size-biggest: 3.5rem;
$font-size-big: 3rem;
$font-size-medium: 1.8rem;

$blue-1: #CCE0F4;
$blue-2: #1E9cD7;
$blue-3: #0075BE;
$blue-4: #005B97;

//$background-image-gradient: linear-gradient(to right, #ffffff 0%, #00BFFF 25%, #042478 100%);
$background-image-gradient: linear-gradient(30deg, rgba(27,226,167,0.7791317210477942) 8%, rgba(21,212,238,0.7007003484987745) 39%, rgba(202,121,245,0.675490264465161) 100%);
$background-image-gradient-text: linear-gradient(to right, #ffffff 0%, #00BFFF 100%);