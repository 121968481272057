.information {
  width: 100%;
  background: white;
  padding-top: 12rem;
  @include respond(phone) {
    padding-top: 8rem;
  }

  &__box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(41.66667% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;

    @include respond(tab-land) {
      margin-left: calc(8.33333% + 1rem);
      width: calc(61.66667% - 2rem);
    }

    @include respond(tab-port) {
      margin-left: calc(4.33333% + 1rem);
      width: calc(80.66667% - 2rem);
    }

    @include respond(phone) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    &__title {
      margin-bottom: 4.5rem;
      & h2 {
        font-family: 'Krylon', serif;
        font-size: 5.2rem;
        line-height: 1em;
        font-weight: 100;
        color: #b89535;
        & b {
          font-size: 5.2rem;
          font-weight: 300;
          color: #4a4a49;
          line-height: 1em;
        }
      }
    }

    &__text {
      margin-bottom: 6rem;
      & p {
        font-family: 'Avenir', serif;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5em;
        color: #4a4a49;
      }
    }
  }

  &__methods {
    margin-left: calc(8.33333% + 1rem);
    width: calc(83.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 6rem;
    @include respond(tab-port) {
      margin-left: calc(4.33333% + 1rem);
      width: calc(91.66667% - 2rem);
    }
    @include respond(phone) {
      width: calc(100% - 2rem);
      margin-left: 1rem;
      margin-bottom: 2rem;
    }

    &__list {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      list-style: none;
      @include respond(tab-port) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        width: 28.08219%;
        margin-bottom: 6rem;
        list-style: none;
        position: relative;
        & img {
          width: 100%;
          outline: 0;
          vertical-align: middle;
        }
        @include respond(tab-land) {
           width: 40%;
         }

        @include respond(tab-port) {
          width: 46%;
        }
        @include respond(phone) {
          width: 80%;
        }

        &__container {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          &__circle {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-radius: 50%;
            background-color: #f9f7f7;

            & img {
              width: 100%;
              outline: 0;
              vertical-align: middle;
            }
          }

          &__middle-holder {
            display: table;
            width: 100%;
            height: 100%;
            position: relative;

            &__middle {
              vertical-align: middle;
              width: 100%;
              display: table-cell;
              border: 0;
              position: relative;

              &__center {
                padding-right: 3em;
                padding-left: 3em;
                text-align: center;
                border: 0;
                display: flex;
                flex-direction: column;
                align-items: center;

                &__icon {
                  display: inline-block;
                  width: 4rem;
                  order: 1;

                  &--mail {
                    width: 5rem;
                    & svg {
                      width: 5rem;
                    }
                  }
                  & svg path {
                    transition: stroke .4s ease;
                    stroke: #4a4a49;
                    stroke-width: 2;
                    fill: none;
                  }
                }

                &__text {
                  margin-top: 2rem;
                  font-size: 2rem;
                  font-weight: 300;
                  order: 2;

                  &--a {
                    &:hover + .information__methods__list__item__container__middle-holder__middle__center__icon {
                      & svg path {
                        stroke: $color-orange;
                      }
                    }
                  }

                  & a {
                    color: $color-orange;
                    text-decoration: underline;
                    transition: opacity .4s ease;

                    &:hover {
                      opacity: .6;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}