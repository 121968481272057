@mixin center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin gradient-text {
  background-image: $background-image-gradient;
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

//MEDIA QUERY MANAGER
/*
0-600px       Phone
600-900px     Tablet portrait
900-1200px    Tablet landscape
1200-1800px   Normal
1800+         Big desktop

breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
 */
@mixin respond($breakpoint) {
  @if($breakpoint == phone) {
    @media (max-width: 37.5em) {@content};
  }
  @if($breakpoint == tab-port) {
    @media (max-width: 56.25em) {@content};
  }
  @if($breakpoint == tab-land) {
    @media (max-width: 75em) {@content};
  }
  @if($breakpoint == tab-land-plus) {
    @media (max-width: 90em) {@content};
  }
  @if($breakpoint == big-desktop) {
    @media (min-width: 112.5em) {@content};
  }
}
