.footer {
  padding-bottom: 4.5rem;
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212121;
  padding-top: 5.5rem;

  &__move-up {
    position: absolute;
    bottom: calc(9% + 4.5rem);
    right: 2.5%;
    width: 6rem;
    height: 6rem;
    border: 1px solid #373737;
    border-radius: 50%;
    transition: border-color .4s ease;

    @include respond(tab-port) {
      display: none;
    }

    &:hover {
      border-color: #b89535;
      & .footer__move-up__btn {
        & svg {
          transform: translateY(-.7rem) rotate(-90deg);
        }
      }
    }

    &__btn {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: currentColor;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      border: none;
      background: transparent;
      box-shadow: none;

      & svg {
        transition: transform .4s ease;
        transform: rotate(-90deg);
        & path {
          stroke: #b89535;
        }
      }
    }
  }

  &__logo-box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(83.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: calc(4.5rem);

    @include respond(phone) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    &__logo {
      pointer-events: all;
      justify-content: flex-start;
      display: flex;
      @include respond(phone) {
        justify-content: center;
      }

      &__link {
        display: block;
        text-decoration: none;
        color: currentColor;
        pointer-events: all;
        position: relative;
        height: 40px;
        width: 200px;
        margin-left: -19px;
        transition: opacity .4s ease;
        @include respond(phone) {
          margin-left: 0;
        }

        &:hover {
          opacity: .6;
        }

        & svg {
          position: absolute;
          top: -5px;
          display: block;
          width: 100%;
          color: currentColor;
          height: 50px;

          & g {
            fill: white;
            stroke: white;
            transition: fill .4s ease, stroke .4s ease;

            & polygon {
              fill: $color-orange;
              stroke: $color-orange;
              transition: fill .4s ease, stroke .4s ease;
            }
          }

          & path {
            transition: fill .4s ease;
          }
        }
      }
    }
  }

  &__links-box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(83.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;


    &__links {
      position: relative;
      margin-bottom: 6rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      list-style: none;
      @include respond(tab-port) {
        margin-bottom: 4rem;
      }
      @include respond(phone) {
        flex-direction: column;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #373737;
        top: 0;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #373737;
        top: 100%;
      }

      &__link {
        margin-right: 2.05479%;
        position: relative;
        text-align: center;
        @include respond(phone) {
          &:first-child {
            margin-top: 2rem;
          }
          &:last-child {
            margin-bottom: 2rem;
          }
        }

        &:hover::after {
          transform: scaleX(1);
        }

        &:last-child {
          margin-right: 0;
        }

        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 1px;
          background-color: #b89535;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform .4s ease;
        }

        &__anchor {
          padding: 4rem 0;
          display: block;
          text-decoration: none;
          color: currentColor;

          @include respond(phone) {
            padding: .2rem 0;
          }

          &__title {
            text-align: center;

            & h3 {
              color: #fff;
              font-family: 'Krylon', serif;
              font-size: 2rem;
              line-height: 1.67em;
              font-weight: 100;
            }
          }
        }
      }
    }
  }

  &__info-box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(33.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 6rem;
    position: relative;


    @include respond(tab-port) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
      margin-bottom: 4rem;
    }

    &__info {
      @include respond(tab-port) {
        text-align: center;
      }
      & p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5em;
        color: white;
      }
    }
  }

  &__contact-box {
    margin-left: calc(16.66667% + 1rem);
    width: calc(33.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    align-items: flex-end;
    flex-direction: column;
    display: flex;

    @include respond(tab-port) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    &__contact {
      margin-bottom: 3.2rem;
      width: 90%;
      @include respond(tab-port) {
        width: 100%;
      }

      &__title {
        transition: opacity .4s ease;
        text-align: right;
        margin-bottom: .8rem;
        margin-left: 8.33333%;
        @include respond(tab-port) {
          margin-left: 0;
        }

        &:hover {
          opacity: .6;
        }

        & h3 {
          color: white;
          font-family: 'Krylon', serif;
          font-weight: 100;
          font-size: 2rem;
          line-height: 1.67em;
          @include respond(tab-port) {
            text-align: center;
          }
        }
      }

      &__tel {
        transition: opacity .4s ease;
        text-align: right;
        margin-bottom: .8rem;
        @include respond(tab-port) {
          text-align: center;
        }

        &:hover {
          opacity: .6;
        }

        & p {
          color: #fff;
          font-size: 1.6rem;
          font-weight: 300;
          line-height: 1.5em;


          & a {
            text-decoration: none;
            color: #fff;
          }
        }
      }

      &__mail {
        transition: opacity .4s ease;
        text-align: right;
        @include respond(tab-port) {
          text-align: center;
        }

        &:hover {
          opacity: .6;
        }

        & p {
          color: #b89535;
          font-size: 1.6rem;
          font-weight: 300;
          line-height: 1.5em;

          & a {
            text-decoration: underline;
            color: #b89535;
          }
        }
      }
    }
  }

  &__credits-box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(83.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    position: relative;
    padding-top: 4rem;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond(tab-port) {
      flex-wrap: wrap;
      justify-content: center;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #373737;
    }

    &__creator {
      width: auto;
      margin-bottom: 0;
      text-align: center;
      @include respond(tab-port) {
        width: calc(100%);
        margin-bottom: 1rem;
      }

      & p {
        color: #fff;
        font-size: 1.1rem;
        line-height: 1.333em;
        font-weight: 300;
        margin: 0;

        & a {
          color: #b89535;
          transition: opacity .4s ease;
          font-size: 1.1rem;
          line-height: 1.333em;
          text-decoration: none;

          &:hover {
            opacity: .6;
          }
        }
      }
    }

    &__rules {
      display: flex;
      justify-content: center;

      &__list {
        display: flex;
        margin-bottom: 0;
        list-style: none;

        &__item {
          margin-right: 3rem;
          text-align: center;
          &:last-child {
            margin-right: 0;
          }
          @include respond(tab-port) {
            &:first-child {
              display: none;
            }
          }
          & a {
            color: #fff;
            opacity: .3;
            transition: opacity .4s ease;
            font-size: 1.1rem;
            line-height: 1.333em;
            font-weight: 300;
            text-decoration: none;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
