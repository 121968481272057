.introduction {
  width: 100%;
  background: white;
  padding-top: 12rem;
  @include respond(phone) {
    padding-top: 8rem;
  }

  &__box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(41.66667% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;

    @include respond(tab-land) {
      margin-left: calc(8.33333% + 1rem);
      width: calc(61.66667% - 2rem);
    }

    @include respond(tab-port) {
      margin-left: calc(4.33333% + 1rem);
      width: calc(80.66667% - 2rem);
    }

    @include respond(phone) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    &__title {
      margin-bottom: 4.5rem;
      & h2 {
        font-family: 'Krylon', serif;
        font-size: 5.2rem;
          @include respond(tab-port) {
              font-size: 4rem;
          }
        line-height: 1em;
        font-weight: 100;
        color: #b89535;
        & b {
          font-size: 5.2rem;
            @include respond(tab-port) {
                font-size: 4rem;
            }
          font-weight: 300;
          color: #4a4a49;
          line-height: 1em;
        }
      }
    }

    &__text {
      margin-bottom: 6rem;
      & p {
        font-family: 'Avenir', serif;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5em;
        color: #4a4a49;
      }
    }
  }

  &__images {
    margin-left: calc(8.33333% + 1rem);
    width: calc(83.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 6rem;
    @include respond(tab-port) {
      margin-left: calc(4.33333% + 1rem);
      width: calc(91.66667% - 2rem);
    }
    @include respond(phone) {
      width: calc(100% - 2rem);
      margin-left: 1rem;
      margin-bottom: 2rem;
    }

    &__list {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      list-style: none;
      @include respond(tab-port) {
        flex-direction: column;
      }

      &__item {
        width: 28.08219%;
        margin-bottom: 6rem;
        list-style: none;
        @include respond(tab-port) {
          width: 100%;
        }

        &__img {
          height: 31rem;
          display: block;
          overflow: hidden;
          margin-bottom: 3rem;

          & img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            transition: transform .8s ease;
            border: none;
            outline: none;
          }
        }

        &__title {
          margin-bottom: 1.5rem;
          @include respond(tab-port) {
            width: calc(80.66667% - 2rem);
          }
          @include respond(phone) {
            width: 100%;
          }

          & h3 {
            color: #484848;
            font-family: 'Krylon', serif;
            font-size: 2.4rem;
            line-height: 1em;
            font-weight: 100;
          }
        }

        &__text {
          @include respond(tab-port) {
            width: calc(80.66667% - 2rem);
          }
          @include respond(phone) {
            width: 100%;
          }
          & p {
            font-family: 'Avenir', serif;
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 1.5em;
            color: #4a4a49;
          }
        }
      }
    }
  }

  &__more {
    margin-left: calc(65% + 1rem);
    width: calc(20.66667% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 12rem;

    @include respond(tab-land) {
      margin-left: calc(55% + 1rem);
      width: calc(30.66667% - 2rem);
    }

    @include respond(tab-port) {
      margin-left: calc(45% + 1rem);
      width: calc(40.66667% - 2rem);
    }

    @include respond(phone) {
      margin-bottom: 8rem;
      margin-left: calc(15% + 1rem);
      width: calc(76.66667% - 2rem);
    }

    &__btn {
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.5em;
      color: #4a4a49;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none;

      &:hover {
        & .introduction__more__btn__icon {
          transform: translateX(1.6rem);

          & svg {
            transform: translateX(5px) scale(1.1);
          }
        }

        & .introduction__more__btn__text {
          transform: translateX(.8rem);
          transition-delay: .2s;
        }
      }

      &__icon {
        transition: transform .4s ease;
        flex-shrink: 0;
        width: 6rem;
        height: 6rem;
        border: 1px solid #4b4b4b;
        border-radius: 50%;
        margin-right: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          transition: transform .4s ease;
        }
      }

      &__text {
        transition: transform .4s ease,color .4s ease;
      }
    }
  }
}

.maps {

  &__title-box {
    margin-left: calc(1rem);
    width: calc(100% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    text-align: center;
    margin-bottom: 4rem;
  }

  &__images {
    margin-left: calc(8.33333% + 1rem);
    width: calc(83.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 6rem;
    @include respond(tab-port) {
      margin-left: calc(4.33333% + 1rem);
      width: calc(91.66667% - 2rem);
    }
    @include respond(phone) {
      width: calc(100% - 2rem);
      margin-left: 1rem;
      margin-bottom: 2rem;
    }

    &__list {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      list-style: none;
      @include respond(tab-port) {
        flex-direction: column;
      }

      &__item {
        width: 28.08219%;
        margin-bottom: 6rem;
        list-style: none;
        @include respond(tab-port) {
          width: 100%;
        }

        &__img {
          height: 33rem;
          display: block;
          overflow: hidden;
          margin-bottom: 3rem;

          & img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            object-fit: cover;
            transition: transform .8s ease;
            border: none;
            outline: none;
            margin-left: auto;
            margin-right: auto;
          }
        }

        &__title {
          text-align: center;
          margin-bottom: 1.5rem;
          @include respond(tab-port) {
              width: 100%;
          }
          @include respond(phone) {
            width: 100%;
          }

          & h3 {
            color: #484848;
            font-family: 'Krylon', serif;
            font-size: 2.4rem;
            line-height: 1em;
            font-weight: 100;
          }
        }

        &__text {
          @include respond(tab-port) {
            width: calc(80.66667% - 2rem);
          }
          @include respond(phone) {
            width: 100%;
          }
          & p {
            font-family: 'Avenir', serif;
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 1.5em;
            color: #4a4a49;
          }
        }
      }
    }
  }
}
