.offer {
  padding-top: 12rem;
  background-color: white;
  @include respond(phone) {
    padding-top: 8rem;
  }

  &__right {
    order: 2;
    @include respond(tab-port) {
      margin-left: 4% !important;
      width: calc(48% - 2rem) !important;
    }
    @include respond(phone) {
      order: 1;
    }
  }
  &__left {
    order: 1;
    @include respond(tab-port) {
      margin-left: 1rem !important;
      width: calc(48% - 2rem) !important;
    }
  }
  &__wood {
    background-color: #f0ebe4;
  }

  &__oil {
    &__text {
      margin-bottom: 12rem;
    }
  }

  &__box {
    margin-left: calc(25% + 1rem);
    width: calc(50% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    text-align: center;

    @include respond(tab-port) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    &__content {
      margin-bottom: 4.5rem;

      @include respond(phone) {
        flex-flow: column wrap;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 12rem;
        @include respond(phone) {
          padding-bottom: 8rem;
        }
      }
      align-items: center;

      &__text-box-right {
        margin-left: calc(8.33333% + 1rem);
        width: calc(33.33333% - 2rem);
        @include respond(tab-port) {
          margin-left: 4%;
          width: calc(48% - 2rem);
        }
        @include respond(phone) {
          margin-left: 1rem !important;
          width: calc(100% - 2rem) !important;
        }
        margin-right: 1rem;
        flex: 0 0 auto;

        &__title {
          margin-bottom: 4.5rem;
          @include respond(phone) {
            margin-bottom: 2rem;
          }

          & h3 {
            color: #4a4a49;
            font-family: 'Krylon', serif;
            font-size: 5.6rem;
              @include respond(tab-port) {
                  font-size: 4rem;
              }
            line-height: 1em;
            font-weight: 100;
          }
        }

        &__text {
          @include respond(phone) {
            margin-bottom: 3rem;
          }
          & p {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.5em;
            color: #4a4a49;
          }
        }
      }

      &__left-img {
        margin-left: calc(8.33333% + 1rem);
        width: calc(41.66667% - 2rem);
        margin-right: 1rem;
        flex: 0 0 auto;
        margin-bottom: .8rem;
        @include respond(tab-port) {
          margin-left: 1rem;
          width: calc(48% - 2rem);
        }
        @include respond(phone) {
          margin-left: 1rem !important;
          width: calc(100% - 2rem) !important;
          margin-bottom: 3rem;
        }

        &__img {
          & img {
            width: 100%;
            height: auto;
            display: block;
            border: none;
            outline: none;
          }
        }
      }
    }

    &__title {
      margin-bottom: 4.5rem;
      @include respond(phone) {
        margin-bottom: 2rem;
      }
      & h3 {
        color: $color-orange;
        font-family: 'Krylon', serif;
        font-size: 5.2rem;
          @include respond(tab-port) {
              font-size: 4rem;
          }
        line-height: 1em;
        font-weight: 100;
        & b {
          color: #4a4a49;
        }
      }
    }

    &__text-middle {
      margin-bottom: 4.5rem;
      & p {
        font-size: 2.8rem;
          @include respond(tab-port) {
              font-size: 2.2rem;
          }
        font-weight: 400;
        line-height: 1.5em;
        color: #4a4a49;
      }
    }

    &__text {
      margin-bottom: 12rem;
      @include respond(phone) {
        margin-bottom: 8rem;
      }
      & p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5em;
        color: #4a4a49;
      }
    }

  }
}
