.header {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 4;
  color: white;
  font-family: "linux_biolinumregular", sans-serif;

  &__animate {
    & .header__white {
      color: #4a4a49;
      height: calc(11.4rem - 4px);
      transition: all .4s ease;
      @include respond(tab-port) {
        height: calc(10.9rem - 4px);
      }
    }

    & .header__box .header__box__line {
      & .header__box__line__cell--open {
        & ul li span {
          border-bottom: 1px solid #4a4a49;
          & a {
            color: #4a4a49;
          }
        }
      }
      & .header__box__line__cell__btn {
        color: #4a4a49;
        transition: all .4s ease;
        & span svg path {
          stroke: #4a4a49;
          transition: all .4s ease;
        }
      }
      & .header__box__line__cell .header__box__line__cell__btn .header__box__line__cell__btn__decor span {
        background-color: #4a4a49;
        transition: all .4s ease;
      }
      & .header__box__line__cell-long {

        & .header__box__line__cell-long__logo{
          & .header__box__line__cell-long__logo__link{
            & svg {

              & g {
                fill: #4a4a49;
                stroke: #4a4a49;

                & polygon {
                  fill: $color-orange;
                  stroke: $color-orange;
                }
              }

              & path {
                transition: fill .4s ease;
              }
            }
          }
        }
        & .header__box__line__cell-long__btn--stairs:hover {
          border-color: #b89535;
          transition: all .3s ease;
        }
        & .header__box__line__cell-long__btn {
        border-color: rgba(74, 74, 73, .2);
        transition: all .4s ease;
      }
        &:hover {
          & .header__box__line__cell-long__btn__arrow {
            color: #b89535;

            & svg path {
              stroke: #b89535;
              transition: all .3s ease;
            }
          }
          & .header__box__line__cell-long__btn__price {
            color: #b89535;
            transition: all .3s ease .1s;
          }
        }
        & span {
          color: #4a4a49;
          transition: all .4s ease;
        }
        & span svg path {
          stroke: #4a4a49;
          transition: all .4s ease;
        }
      }
      & .header__box__line__cell-s-long:after {
        background-color: rgba(74,74,73,.2);
        transition: all .4s ease;
      }
    }
  }

  &__nav {
    & .header__white {
      background: #1d1d1d;
      height: calc(11.4rem - 4px);
      transition: all .4s ease;
      @include respond(tab-port) {
        height: calc(16.8rem - 4px);
      }
    }
    & .header__box {
      & .header__box__line {
        & .header__box__line__cell--open {
          & ul li span {
            border-bottom: 1px solid #fff;
            & a {
              color: #fff;
            }
          }
        }
        & .header__box__line__cell {
          & .header__box__line__cell__btn {
            color: #fff;
            & span svg path {
              stroke: #fff;
            }
            & .header__box__line__cell__btn__decor {
              & span {
                background-color: #fff;
              }
              & .header__box__line__cell__btn__decor--1 {
                transform: rotate(45deg);
                width: 2.15rem;
              }
              & .header__box__line__cell__btn__decor--2 {
                width: 0;
              }
              & .header__box__line__cell__btn__decor--3 {
                transform: rotate(-45deg);
                width: 2.15rem;
              }
            }
          }
        }
        & .header__box__line__cell-long--price--box{
          @include respond(tab-port) {
            visibility: visible;
          }
        }

        & .header__box__line__cell-long {

          & .header__box__line__cell-long__logo {
            & .header__box__line__cell-long__logo__link {
              & svg {

                & g {
                  fill: white;
                  stroke: white;
                  transition: fill .4s ease, stroke .4s ease;

                  & polygon {
                    fill: $color-orange;
                    stroke: $color-orange;
                    transition: fill .4s ease, stroke .4s ease;
                  }
                }
              }
            }
          }
          & .header__box__line__cell-long__btn {
            border-color: hsla(0,0%,100%,.2);
            transition: all .4s ease;

          }
          & .header__box__line__cell-long--price--box .header__box__line__cell-long__btn--stairs:hover {
            border: 1px solid #b89535;
            transition: border-color .3s ease;

            & .header__box__line__cell-long__btn__arrow {
              transform: translateX(.7rem);
            }

            & span svg path {
              transition: stroke .3s ease;
              stroke: #b89535;
            }
            & .header__box__line__cell-long__btn__price {
              transition: color .3s ease .1s, transform .3s ease .1s;
              color: #b89535;
              transform: translateX(.7rem);
            }
          }
          & span {
            color: #fff;
          }
          & span svg path {
            stroke: #fff;
          }
        }
        & .header__box__line__cell-s-long {
          @include respond(tab-port) {
            transform: translateY(60px);
          }
        }
        & .header__box__line__cell-s-long:after {
          background-color: hsla(0,0%,100%,.2);
          transition: all .4s ease;
        }
      }
    }
  }

  &__white {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    width: 100%;
    height: 0;
    transition: all .4s ease;
  }

  &__box {
    padding-left: 6rem;
    padding-right: 6rem;
    height: 7.8rem;
    padding-top: 3.4rem;
    max-width: 142rem;
    margin-left: auto;
    margin-right: auto;
    transition: transform .4s ease;

    @include respond(tab-port) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      height: calc(100% + 28px);
      background-color: #fff;
    }

    &__line {
      margin-bottom: 3rem;
      align-items: center;
      margin-left: -1rem;
      margin-right: -1rem;
      display: flex;
      flex-flow: row wrap;
      font-size: 1.6rem;
      font-weight: 200;
      line-height: 1.5em;

      &__cell-s-long {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        margin-right: 1rem;
        padding-bottom: 3rem;
        position: relative;
        order: 5;
        flex: 0 0 auto;
        transition: transform .4s ease;

        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: hsla(0,0%,100%,.2);
          transform: scale(0);
          transform-origin: left;
          animation: headerLine .8s ease .4s forwards;
        }
      }

      &__cell-long {
        margin-left: calc(22.5% + 1rem);
        width: calc(21.66667% - 2rem);
        margin-right: 1rem;
        z-index: 3;

        &--logo {
          @include respond(tab-port) {
            margin-left: calc(8.33333% + 1rem);
            width: calc(50% - 2rem);
          }
        }

        &--price {
            margin-left: calc(11.49967% + 1rem);
            width: calc(27.66667% - 2rem);
            margin-right: 0;
          @include respond(tab-land) {
            margin-left: calc(4.06667% + 1rem);
            width: calc(35% - 2rem);
          }
          @include respond(tab-port) {
            width: calc(100% - 2rem);
            margin-left: 1rem;
            order: 4;
          }
          @include respond(phone) {
            width: calc(100% - 2rem);
            margin-left: 1rem;
            order: 4;
          }
          &--box {
            display: flex;
            justify-content: center;

            @include respond(tab-port) {
              left: 50%;
              transform: translateX(-50%);
              width: calc(50% - 5rem);
              position: absolute;
              visibility: hidden;
              margin-top: 2rem;
            }
            @include respond(phone) {
              width: calc(100% - 5rem);
            }
          }
        }

        &__logo {
          pointer-events: all;
          display: flex;
          justify-content: center;


          &--intro {
            opacity: 0;
            animation: headerOpacity .4s ease .8s forwards;
          }

          &__link {
            display: block;
            transition: opacity .4s ease;
            text-decoration: none;
            color: currentColor;
            pointer-events: all;
            position: relative;
            height: 40px;
            width: 200px;

            &:hover {
              opacity: .6;
            }

            & svg {
              position: absolute;
              top: -5px;
              display: block;
              width: 100%;
              color: currentColor;
              height: 50px;

              & g {
                fill: white;
                stroke: white;
                transition: fill .4s ease, stroke .4s ease;

                & polygon {
                  fill: $color-orange;
                  stroke: $color-orange;
                  transition: fill .4s ease, stroke .4s ease;
                }
              }

              & path {
                transition: fill .4s ease;
              }
            }
          }
        }

        &__btn {
          height: 4.5rem;
          background: transparent;
          border: 1px solid hsla(0,0%,100%,.2);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 100%;
          transition: border-color .4s ease;
          min-height: 0;
          min-width: 0;

          & span svg path {
            transition: stroke .4s ease;
          }

          &--stairs {
              text-decoration: none;
            &--intro {
              opacity: 0;
              animation: headerOpacity .4s ease 1.2s forwards;
            }
            &:hover {
              border: 1px solid #b89535;
              transition: border-color .3s ease;

              & .header__box__line__cell-long__btn__arrow {
                transform: translateX(.7rem);
              }

              & span svg path {
                transition: stroke .3s ease;
                stroke: #b89535;
              }
              & .header__box__line__cell-long__btn__price {
                transition: color .3s ease .1s, transform .3s ease .1s;
                color: #b89535;
                transform: translateX(.7rem);
              }
            }
            & span {
              transition: transform .3s ease, color .3s ease;
            }
          }

          &__arrow {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1.5rem;
            transition: all .4s ease;
          }

          & span {
            color: white;
            font-family: 'Avenir', serif;
            font-weight: 400;
            line-height: 1.5em;
            font-size: 1.6rem;
          }
        }
      }

      &__cell {
        margin-left: 1rem;
        margin-right: 1rem;
        width: calc(8.33333% - 2rem);
        position: relative;
        @include respond(tab-port) {
          width: calc(16.66667% - 2rem);
        }

        &--lang {
          display: flex;
          align-items: center;
          justify-content: center;
          @include respond(tab-port) {
            order: 4;
            margin-left: calc(8.33333% + 1rem);
          }
        }

        &--open {
          & .header__box__line__cell__list  {
            & .header__box__line__cell__list__item--1 {
              transition-delay: .1s;
            }
            & .header__box__line__cell__list__item--2 {
              transition-delay: .2s;
            }
            & .header__box__line__cell__list__item {
              transform: translateY(0);
            }
          }
          & .header__box__line__cell__btn--lang {
            opacity: 0;
            transition: opacity .4s ease;
          }
          & .header__box__line__cell__list {
            z-index: 3;
          }
        }

        &__list {
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          display: flex;
          margin-bottom: 0;
          list-style: none;
          pointer-events: all;
          z-index: 1;
          @include respond(tab-port) {
            left: 1rem;
          }
          @include respond(phone) {
            left: 0;
          }

          &__item {
            position: relative;
            transform: translateY(100%);
            transition: transform .4s ease;
            margin-right: 1rem;
            list-style: none;
            cursor: pointer;
            & span {
              border-bottom: 1px solid white;
            }
            & span a {
              text-decoration: none;
              color: currentColor;
              transition: all .4s ease;
            }

            &:hover span a{
              opacity: .6;
            }
          }
        }

        &__btn {
          width: 2.7rem;
          height: 2.7rem;
          cursor: pointer;
          color: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
          font-size: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          border: none;
          box-shadow: none;
          transition: all .4s ease;
          position: relative;

          &--nav {
            &--intro {
              opacity: 0;
              animation: headerOpacity .4s ease forwards;
            }
            &:hover span {
              opacity: .6;
            }
            & span {
              opacity: .8;
              transition: opacity .4s ease;
            }
          }

          &--lang {
            padding-right: 1.5rem;
            transition: all .4s ease;
            z-index: 2;
            &--intro {
              opacity: 0;
              animation: headerOpacity .4s ease .4s forwards;
            }

            & span {
              position: absolute;
              top: 45%;
              right: .8rem;
              width: 1rem;
              height: 1rem;
              transition: transform .4s ease;
              transform: translate(50%,-30%);
            }

            &:hover span {
              transform: translate(50%, 0);
            }
          }

          &__decor {
            position: relative;
            width: 2.1rem;
            flex-direction: column;
            display: flex;

            & svg path {
              transition: all .4s ease;
            }

            & span {
              width: 100%;
              height: 1px;
              background-color: #fff;
              margin-bottom: .7rem;
              transform-origin: left;
              transition: all .4s ease;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
