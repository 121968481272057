.portfolio {
  background-color: #f0ebe4;
  padding-top: 12rem;
  overflow: hidden;

  &__line {
    margin-left: calc(8.33333% + 1rem);
    width: calc(83.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 12rem;

    &__progress {
      position: relative;
      top: calc(100% - 1px);
      height: 1px;
      background-color: rgba(74,74,73,.1);

      &__scale {
        transform: translate3d(0px, 0px, 0px) scaleX(0.2) scaleY(1);
        transition-duration: 800ms;
        transform-origin: left top;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #b89535;
      }
    }
  }

  &__title-box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(58.33333% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 4.5rem;

    @include respond(tab-land) {
      margin-left: calc(8.33333% + 1rem);
      width: calc(61.66667% - 2rem);
    }

    @include respond(tab-port) {
      margin-left: calc(4.33333% + 1rem);
      width: calc(80.66667% - 2rem);
    }

    @include respond(phone) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    & h2 {
      color: #4a4a49;
      font-family: 'Krylon', serif;
      font-size: 5.2rem;
        @include respond(tab-port) {
            font-size: 4rem;
        }
      line-height: 1em;
      font-weight: 100;
    }
  }

  &__text-box {
    margin-left: calc(8.33333% + 1rem);
    width: calc(50% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 6rem;

    @include respond(tab-land) {
      margin-left: calc(8.33333% + 1rem);
      width: calc(61.66667% - 2rem);
    }

    @include respond(tab-port) {
      margin-left: calc(4.33333% + 1rem);
      width: calc(80.66667% - 2rem);
    }

    @include respond(phone) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    & p {
      font-family: 'Avenir', serif;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.5em;
      color: #4a4a49;
    }
  }

  &__box {
    flex: 0 0 auto;
    margin-left: calc(8.33333% + 1rem);
    width: calc(91.66667% - 2rem);
    margin-right: 1rem;

    &__cards {
      list-style: none;
      padding: 0;
      z-index: 1;
      touch-action: pan-y;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      &__nav {
        width: 8.5rem;
        height: 5rem;
        border-radius: 6.25rem;
        background-color: #f9f7f7;
        top: 13rem;
        transform: none;
        right: 5rem;
        display: flex;
        position: absolute;
        z-index: 1;
        align-items: center;
        justify-content: center;

        &__part {

          &:first-child {
            margin-right: 2rem;
          }

          &__btn {
            cursor: pointer;
            color: currentColor;
            width: 1.2rem;
            height: 1.2rem;
            transition: opacity .4s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: transparent;
            box-shadow: none;

            &--opacity {
              opacity: .6;
              cursor: default !important;
            }

            &--1 {
              transform: rotate(180deg);
            }

            & svg {
              transition: transform .4s ease;
            }
          }
        }

        &__right {

        }
      }

      &__list {
        position: relative;
        list-style: none;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        margin-bottom: 5rem;
        transition: transform .4s ease;

        &__element {
          flex-shrink: 0;
          position: relative;
          transition-property: transform;
          width: 240px;
          margin-right: 80px;

          &__square {
            display: block;
            transition: transform .8s ease;

            &:hover img {
              transform: scale(1.15);
            }

            &__content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              &__top {
                margin-bottom: 1rem;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                &__type {
                  margin-bottom: 1rem;

                  & p {
                    font-family: 'Avenir', serif;
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 1.5em;
                    color: #4a4a49;
                  }
                }

                &__icons {

                  & ul {
                    display: flex;
                    align-items: center;
                    list-style: none;

                    & li {
                      margin-right: 1.2rem;
                      &:last-child {
                        margin-right: 0;
                      }

                        & svg {
                            width: 3.5rem;
                            height: 3.5rem;
                            & rect {
                                fill: none;
                                stroke: $color-orange;
                                transition: all .3s ease;
                                stroke-width: 2px;
                            }

                            & polygon {
                                stroke: $color-orange;
                                fill: none;
                                transition: all .3s ease;
                                stroke-width: 2px;
                            }
                            & polyline {
                                stroke: $color-orange;
                                fill: none;
                                transition: all .3s ease;
                                stroke-width: 2px;
                            }
                            & .special__fill__none {
                                fill: none;
                            }
                            & g {
                                & polygon {
                                    stroke: $color-orange;
                                    fill: none;
                                    transition: all .3s ease;
                                    stroke-width: 2px;
                                }
                                & path {
                                    stroke: $color-orange;
                                    fill: $color-orange;
                                    transition: all .3s ease;
                                    stroke-width: 2px;
                                }
                                & polyline {
                                    stroke: $color-orange;
                                    fill: none;
                                    transition: all .3s ease;
                                    stroke-width: 2px;
                                }
                                & g {
                                    path {
                                        fill: $color-orange;
                                        transition: all .3s ease;
                                        stroke-width: 2px;
                                    }
                                }
                            }

                            & path,line {
                                stroke-width: 2px;
                                stroke: $color-orange;
                                transition: all .3s ease;
                            }

                        }
                    }
                  }
                }
              }

              &__bottom {
                margin-bottom: 1rem;

                & h3 {
                  color: #484848;
                  font-family: "Krylon", serif;
                  font-size: 2.4rem;
                  line-height: 1em;
                  font-weight: 100;
                }
              }
            }

            &__img {
              max-width: 536px;
              display: block;
              height: 320px;
              overflow: hidden;
              margin-bottom: .8rem;

              & img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform .8s ease;
                border: none;
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  &__more {
    margin-left: calc(65% + 1rem);
    width: calc(20.66667% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    margin-bottom: 12rem;

    @include respond(tab-land) {
      margin-left: calc(55% + 1rem);
      width: calc(30.66667% - 2rem);
    }

    @include respond(tab-port) {
      margin-left: calc(45% + 1rem);
      width: calc(40.66667% - 2rem);
    }

    @include respond(phone) {
      margin-bottom: 8rem;
      margin-left: calc(15% + 1rem);
      width: calc(76.66667% - 2rem);
    }

    &__btn {
      text-transform: capitalize;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.5em;
      color: #4a4a49;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none;

      &:hover {
        & .portfolio__more__btn__icon {
          transform: translateX(1.6rem);

          & svg {
            transform: translateX(5px) scale(1.1);
          }
        }

        & .portfolio__more__btn__text {
          transform: translateX(.8rem);
          transition-delay: .2s;
        }
      }

      &__icon {
        transition: transform .4s ease;
        flex-shrink: 0;
        width: 6rem;
        height: 6rem;
        border: 1px solid #4b4b4b;
        border-radius: 50%;
        margin-right: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          transition: transform .4s ease;
        }
      }

      &__text {
        transition: transform .4s ease,color .4s ease;
      }
    }
  }
}
