.calculator {
  padding-top: 12rem;
  background-color: #f9f7f7;

  @include respond(phone) {
    padding-top: 8rem;
  }

  &__lr__body {
    @include respond(phone) {
      justify-content: center !important;
    }
  }

  &__chosen__svg {
    & polygon {
      stroke: $color-orange !important;
      fill: none;
    }
    & polyline {
      stroke: $color-orange !important;
      fill: none;
    }
    & path,line {
      stroke: $color-orange !important;
    }
    & g {
      & path {
        stroke: $color-orange !important;
        fill: $color-orange !important;
      }
      & polygon {
        stroke: $color-orange !important;
        fill: none;
      }
      & polyline {
        stroke: $color-orange !important;
        fill: none;
      }
      & g {
        path {
          fill: $color-orange !important;
        }
      }
    }
    & rect {
      stroke: $color-orange !important;
    }
    & .special__fill__none {
      fill: none !important;
    }
  }

  &__chosen__text {
    color: $color-orange !important;
    & span {
      transform: scale(1) !important;
      background-color: $color-orange !important;
    }
  }

  &__left {
    & .calculator__box__form-box__form__section__body__right {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      display: none;
    }
    & .calculator__box__form-box__form__section__body__choice button:first-child {
      border: 1px solid $color-orange;
      color: $color-orange;
    }
  }
  &__right {
    & .calculator__box__form-box__form__section__body__left {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      display: none;
    }
    & .calculator__box__form-box__form__section__body__choice button:last-child {
      border: 1px solid $color-orange;
      color: $color-orange;
    }
  }

  &__box {
    min-width: 50rem;
    max-width: calc(50% - 2rem);
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 0 0 auto;
    text-align: center;

    @include respond(phone) {
      max-width: calc(100% - 2rem);
      width: calc(100% - 2rem);
      min-width: calc(100% - 2rem);
    }

    &__title {
      margin-bottom: 4.5rem;

      & h2 {
        font-family: 'Krylon', serif;
        font-size: 5.2rem;
          @include respond(tab-port) {
              font-size: 4rem;
          }
        line-height: 1em;
        font-weight: 100;
        color: #b89535;

        & b {
          font-size: 5.2rem;
          font-weight: 300;
          color: #4a4a49;
          line-height: 1em;
        }
      }
    }

    &__text {
      margin-bottom: 4.5rem;

      & p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.5em;
        color: #4a4a49;
      }
    }

    &__form-box {
      color: #4a4a49;
      &__form {

          &__errors {
              font-size: 1.6rem;
              text-align: center;
              color: red;
              transition: all .5s ease;
          }

        &__submit {
          cursor: pointer;
          border: 1px solid #4a4a49;
          padding: 10px 30px;
          color: #4a4a49;
          font-size: 2rem;
          margin-top: 5rem;
          margin-bottom: 12rem;
          background: transparent;
          outline: none;
          transition: color .5s ease, border .5s ease;

            &--error {
                animation: shakeError 0.4s 1 linear;
            }

          @include respond(phone) {
            margin-top: 4rem;
            margin-bottom: 8rem;
          }

          &:hover {
            border: 1px solid $color-orange;
            color: $color-orange;
          }
        }

        &__section {
          margin-bottom: 3rem;
          margin-top: 5rem;

          &__notes {
            width: 100%;
            display: flex;
            flex: 0 0 auto;
            position: relative;
            flex-wrap: wrap;
            justify-content: space-between;
             & textarea {
               width: 100%;
               font-size: 1.6rem;
               color: #4a4a49;
               background-color: transparent;
               border: 1px solid #4a4a49;
               min-height: 150px;
               outline: none;
               padding: 5px;
             }
          }

          &__title {
            position: relative;
            padding-bottom: 2rem;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: rgba(74, 74, 73, 0.4);
              top: 100%;
            }
            & h3 {
              text-align: center;
              font-size: 2rem;
              font-weight: 300;
            }
          }
          &__body {
            margin-top: 1rem;
            display: flex;
            flex: 0 0 auto;
            position: relative;
            flex-wrap: wrap;
            justify-content: space-between;

            &__margin {
              margin-top: 3rem;
            }

            &__right {
              width: 100%;
              display: flex;
              flex: 0 0 auto;
              position: relative;
              flex-wrap: wrap;
              justify-content: space-between;
              transition: opacity .5s ease;
              @include respond(phone) {
                justify-content: center;
              }
            }
            &__left {
              width: 100%;
              display: flex;
              flex: 0 0 auto;
              position: relative;
              flex-wrap: wrap;
              justify-content: space-between;
              transition: opacity .5s ease;
              @include respond(phone) {
                justify-content: center;
              }
            }
            &__holes {
              width: 100%;
              display: flex;
              flex: 0 0 auto;
              position: relative;
              flex-wrap: wrap;
              justify-content: space-between;
              transition: opacity .5s ease;

              @include respond(phone) {
                justify-content: center;
              }
            }
            &__checkboxes {
              width: 100%;
              display: flex;
              flex: 0 0 auto;
              position: relative;
              flex-wrap: wrap;
              justify-content: space-between;

              &__name {
                width: 30%;
                cursor: pointer;
                text-align: center;
                font-size: 1.6rem;
                color: #4a4a49;
                transition: color .3s ease;
                margin-bottom: 2rem;
                &:hover {
                  color: $color-orange;
                  & span {
                    background: $color-orange;
                    transform: scale(1);
                  }
                }
                & span {
                  margin-top: 5px;
                  width: 100%;
                  display: block;
                  height: 1px;
                  background-color: #4a4a49;
                  transform: scale(0);
                  transition: all .5s ease;
                }
              }
            }
            &__choice {
              width: 100%;
              display: flex;
              flex: 0 0 auto;
              position: relative;
              flex-wrap: wrap;
              justify-content: space-between;
              & button {
                cursor: pointer;
                width: 45%;
                padding: 5px;
                text-transform: uppercase;
                border: 1px solid #4a4a49;
                font-size: 1.6rem;
                color: #4a4a49;
                background: transparent;
                transition: all .5s ease;
                &:hover {
                  border: 1px solid $color-orange;
                  color: $color-orange;
                }
              }
            }

            &__img {
              width: 100%;
              & img {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
              }
            }

            &__title {
              font-size: 1.8rem;
              width: 100%;
              text-align: center;
              margin-bottom: 2rem;
              font-weight: 300;
            }

            &__line {
              width: 100%;
              height: 1px;
              background-color: rgba(74, 74, 73, 0.4);
              margin-bottom: 2rem;
              display: block;
            }

            &__hole {
              width: 45%;
              position: relative;
              margin-bottom: 2rem;

              @include respond(phone) {
                width: 100%;
                max-width: 300px;
                height: 150px;
              }

              & svg {
                width: 90%;
                cursor: pointer;

                @include respond(phone) {
                  width: 100%;
                  max-width: 300px;
                  height: 150px;
                }
                & g line {
                  stroke-width: 1px;
                  stroke: black;
                  transition: all .3s ease;
                }
              }

              &:hover {
                & svg {
                  //box-shadow: rgba(184, 149, 53, 0.2) 0px 2px 8px 0px;
                  & g line {
                    stroke: $color-orange;
                  }
                }
              }


            }

            &__svg {
              width: 45%;
              position: relative;
              cursor: pointer;

              @include respond(phone) {
                width: 80%;
              }

              & p {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                font-size: 1.6rem;
                color: #4a4a49;
              }

              & rect {
                fill: none;
                stroke: black;
                transition: all .3s ease;
                stroke-width: 1px;
              }

              & svg {
                width: 90%;
                & polygon {
                  stroke: black;
                  fill: none;
                  transition: all .3s ease;
                  stroke-width: 1px;
                }
                & polyline {
                  stroke: black;
                  fill: none;
                  transition: all .3s ease;
                  stroke-width: 1px;
                }
                & .special__fill__none {
                  fill: none;
                }
                & g {
                  & polygon {
                    stroke: black;
                    fill: none;
                    transition: all .3s ease;
                    stroke-width: 1px;
                  }
                  & path {
                    stroke: black;
                    fill: black;
                    transition: all .3s ease;
                    stroke-width: 1px;
                  }
                  & polyline {
                    stroke: black;
                    fill: none;
                    transition: all .3s ease;
                    stroke-width: 1px;
                  }
                  & g {
                    path {
                      fill: black;
                      transition: all .3s ease;
                      stroke-width: 1px;
                    }
                  }
                }

                & path,line {
                  stroke-width: 1px;
                  stroke: black;
                  transition: all .3s ease;
                }

              }

              &:hover {
                & svg {
                  & polygon {
                    stroke: $color-orange;
                    fill: none;
                  }
                  & polyline {
                    stroke: $color-orange;
                  }
                  & path,line {
                    stroke: $color-orange;
                  }
                  & g {
                    & path {
                      stroke: $color-orange;
                      fill: $color-orange;
                    }
                    & polygon {
                      stroke: $color-orange;
                      fill: none;
                    }
                    & polyline {
                      stroke: $color-orange;
                      fill: none;
                    }
                    & g {
                      path {
                        fill: $color-orange;
                      }
                    }
                  }
                  & rect {
                    stroke: $color-orange;
                  }
                  & .special__fill__none {
                    fill: none;
                  }
                }
              }


            }

            &__middle {
              width: 10%;
              height: 3.5rem;
              margin-bottom: 1rem;
            }

            &__element {
              font-size: 1.6rem;
              position: relative;
              bottom: 0;
              width: 45%;
              height: 4.5rem;


              &--cm {
                position: absolute;
                right: 0;
                bottom: .3rem;
              }

              & input {
                position: absolute;
                width: 100%;
                display: block;
                bottom: .3rem;
                outline: none;
                border: none;
                background: transparent;
                text-align: center;
                cursor: pointer;
                z-index: 2;
                color: $color-orange;
                  font-size: 1.6rem;

                &:focus + .control-label {
                  //transform: scale(.8) translate(-15%, -100%);
                  transform: translate(-50%, -100%) scale(.8);
                  & + .focus-input::before {
                    width: 100%;
                  }
                }

                &:not(:placeholder-shown) + .control-label {
                  //transform: scale(.8) translate(-15%, -100%);
                  transform: translate(-50%, -100%) scale(.8);
                }

              }

              & label {
                position: absolute;
                width: 100%;
                left: 50%;
                bottom: .3rem;
                transform: translateX(-50%);
                transition: all .8s ease;
              }

              & span {
                &::before {
                  content: "";
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 0;
                  height: 1px;
                  background-color: #b89535;
                  top: 100%;
                  transition: all .8s ease;
                  z-index: 2;
                }
              }

                &--error {
                    &::after {
                        background-color: rgba(220,20,60, 0.4) !important;
                    }
                }

              &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(74, 74, 73, 0.4);
                top: 100%;
              }
            }

            &__size {
              @include respond(phone) {
                width: 80%;
              }
              & input, label {
                @include respond(phone) {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}

.j-center {
  justify-content: center;
}
.hide {
    transform: scaleY(0);
}