@keyframes headerLine {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes headerOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes navLink {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes introImg {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

[data-aos="opacity-in"] {
  opacity: 0;
  transition: opacity 1s ease-out;
  transition-duration: 1s !important;

  &.aos-animate {
    opacity: 1;
  }
}

@keyframes alertInOut {
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1);
    }
    75% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);;
    }
}

@keyframes shakeError {
    0% {
        transform: translate(30px);
    }
    20% {
        transform: translate(-30px);
    }
    40% {
        transform: translate(15px);
    }
    60% {
        transform: translate(-15px);
    }
    80% {
        transform: translate(8px);
    }
    100% {
        transform: translate(0px);
    }
}
