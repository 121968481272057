.map {
  padding-top: 12rem;
  background-color: #f9f7f7;
  padding-left: 6rem;
  padding-right: 6rem;
  @include respond(tab-port) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @include respond(phone) {
    padding-top: 8rem;
  }

  &__box {
    margin-left: calc(1rem);
    width: calc(100% - 2rem);
    margin-right: 1rem;
    flex: 0 0 auto;
    text-align: center;

    @include respond(tab-port) {
      margin-left: 1rem;
      width: calc(100% - 2rem);
    }

    &__title {
      margin-bottom: 4.5rem;
      & h3 {
        color: #4a4a49;
        font-family: 'Krylon', serif;
        font-size: 5.6rem;
        line-height: 1em;
        font-weight: 100;
      }
    }

    &__container {
      position: relative;
      padding-bottom: 35%; // This is the aspect ratio
      height: 0;
      overflow: hidden;
      margin-bottom: 12rem;

      @include respond(tab-land) {
        padding-bottom: 45%;
      }
      @include respond(phone) {
        padding-bottom: 65%;
      }

      & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}