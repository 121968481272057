.navigation {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  visibility: hidden;
  background-color: #1d1d1d;
  transform: translate3d(0,-100%,0);
  transition: transform .8s ease .6s,visibility 0s 3s;
  font-family: 'Krylon', serif;
  font-weight: 100;

  &__active {
    visibility: visible;
    transform: none;
    transition-delay: 0s;

    & .navigation__container {
      visibility: visible;
      & .navigation__container__list {
        visibility: visible;
        & .navigation__container__list__item {
          visibility: visible;
          & .navigation__container__list__item__link {
            visibility: visible;
            & span {
              opacity: 1;
            }
            & .navigation__container__list__item__link:before {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  &__container {
    padding: 0 7rem;
    max-width: 142rem;
    margin: 0 auto;

    &__list {
      padding-bottom: 14rem;
      margin-top: 21.5rem;
      margin-bottom: 0;
      list-style: none;

      &__item {
        padding-bottom: 0 !important;
        margin-bottom: 3.5em !important;
        width: fit-content;
        padding-right: 3.5rem;
        color: #fff;
        transition: all .4s ease,color 0s ease;
        list-style: none;
        overflow: hidden;

        &:hover {
          color: #b89535;

          & .navigation__container__list__item__link {
            & span {
              transform: translateX(6rem);
              font-style: italic;
            }
            & .navigation__container__list__item__link:before {
              transform: scaleX(1);
            }
          }
          & .navigation__container__list__item__image {
            transition-delay: .4s;
            opacity: 1;
            transform: none;
          }
          @include respond(tab-port) {
            & .navigation__container__list__item__image {
              opacity: 0;
              transform: none;
            }
          }
        }

        &__image {
          padding: 11.73611% 27.77778% 4.44444% 41.11111%;
          display: block;
          width: 100%;
          height: 100%;
          pointer-events: none;
          opacity: 0;
          position: fixed;
          top: 0;
          left: 0;
          z-index: -1;
          transform: scale(1.1);
          transition: opacity .4s ease,transform .4s ease;
          list-style: none;

          & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: none;
            pointer-events: none;

            &:focus {
              outline: none;
            }
          }
        }

        &__link {
          padding-left: 2.5rem;
          position: relative;
          display: block;
          z-index: 1;
          transition: color .4s ease;
          text-decoration: none;
          color: currentColor;
          list-style: none;
          transform: translateY(100%);

          &:hover:before {
            transform: scaleX(1);
          }

          & span {
            display: block;
            font-size: 3rem;
            font-weight: 200;
            line-height: 1em;
            transition: transform .4s ease, opacity .8s ease;
            opacity: 0;
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 6.5rem;
            height: 1px;
            background-color: #b89535;
            transform-origin: left;
            transform: scaleX(0);
            transition: transform .4s ease;
            color: currentColor;
            list-style: none;
          }
        }
      }
    }
  }
}