*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // defines what 1rem is
  font-size: 62.5%;

  //@include respond(tab-land) {
  //  font-size: 56.25%;
  //}
  //
  //@include respond(tab-port) {
  //  font-size: 50%;
  //}
  //
  //@include respond(big-desktop) {
  //  font-size: 75%;
  //}
  text-rendering: optimizeLegibility;
}

body {
  box-sizing: border-box;
  font-family: 'Avenir', serif;//Georgia, serif;

  @include respond(tab-land) {
    padding: 0;
  }
}

main {
  margin-top: 100vh;
  position: relative;
  z-index: 3;
}
.grid-container {
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 142rem;
  margin-left: auto;
  margin-right: auto;
}
.grid-x {
  display: flex;
  flex-flow: row wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}
.alert {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 7;
    font-size: 1.8rem;
    padding: .75rem 1.25rem;
    animation: alertInOut 5s ease forwards;
    transform: scale(0);
    border: 1px solid transparent;
    border-radius: .25rem;
}
.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.article__content {
    margin-left: 1rem;
    margin-right: 1rem;
    color: #4a4a49;
    font-size: 1.6rem;
    max-width: 142rem;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 12rem;
    @include respond(tab-port) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    @include respond(phone) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 8rem;
    }
    & ol li a {
        color: $color-orange;
    }

    & p strong {
        font-size: 3rem;
        font-weight: 700;
    }
}
.has-text-align-center {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    &:first-child {
        margin-top: 0;
    }
}
