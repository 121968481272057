.cover {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  &__bg-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &__video {
      position: relative;
      height: 100%;
      background: #212121;

      & video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.1);
        animation: introImg 5s ease forwards;
      }
    }
  }

  &__bg-box {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      &--intro {
        opacity: 0;
        animation: headerOpacity 2s ease forwards;
      }

      & h2 {
        color: #fff;
        font-size: 7.375rem;
        font-weight: 100;
        font-style: normal;
        font-family: 'Krylon', serif;
        text-align: center;
        opacity: .9;

        @include respond(tab-land) {
          font-size: 50px;
        }
        @include respond(tab-port) {
          font-size: 40px;
        }
      }
    }
  }
}